<!--
// nuxt-ui/components/pages/region-tournament/LayoutTournamentKwcNations.vue
-->
<script setup lang="ts">
import { isFutureDate, isPastDate, setAliasUrl } from "~/src/Shared/utils";
import BannerNations from "~/nuxt-ui/components/pages/region-home/BannerNations.vue";
import Countdown from "~/nuxt-ui/components/Shared/Countdown.vue";

const { locale, t } = useI18n();

const embedLanguage = ref<string>(locale.value);
const showCountdown = ref<boolean | undefined>();
const showEmbedTwitch = ref<boolean | undefined>();

const setEmbedChannelSrc = computed(() => {
  if (embedLanguage.value === "en") {
    return "https://player.twitch.tv/?channel=kingsworld&parent=kingsleague.pro&muted=true";
  } else if (embedLanguage.value === "it") {
    return "https://player.twitch.tv/?channel=kingsleague_it&parent=kingsleague.pro&muted=true";
  } else if (embedLanguage.value === "pt") {
    return "https://player.twitch.tv/?channel=kingsleague_br&parent=kingsleague.pro&muted=true";
  } else {
    return "https://player.twitch.tv/?channel=kingsleague&parent=kingsleague.pro&muted=true";
  }
});

onMounted(() => {
  showCountdown.value = isFutureDate("2024-12-05T16:59:59Z");
  showEmbedTwitch.value = isPastDate("2024-12-05T17:00:00Z") && isFutureDate("2024-12-05T18:30:00Z");
});
</script>

<template>
  <banner-nations></banner-nations>
  <section
    class="overflow-hidden md:mx-auto bg-[url('https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/bg-kwc-nations-tournament.jpg')] bg-cover bg-top"
  >
    <div class="max-w-7xl mx-auto py-10 px-4 bigsm:py-20 bigsm:px-10">
      <div class="block bigsm:flex bigsm:items-start bigsm:justify-between">
        <div class="bigsm:w-2/5">
          <NuxtImg
            :src="
              setAliasUrl('https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-470.png')
            "
            alt="Kings World Cup Nations"
            class="hidden bigsm:block w-[370px]"
            width="270"
            height="78"
          />
          <NuxtImg
            :src="
              setAliasUrl('https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-logo-h.png')
            "
            alt="Kings World Cup Nations"
            class="block bigsm:hidden w-full sm:w-1/2"
            width="519"
            height="140.7"
            sizes="272px sm:327px md:381px"
          />

          <div :class="showCountdown || showEmbedTwitch ? 'bigsm:hidden min-h-[150px]' : 'hidden'">
            <div v-if="showCountdown" class="flex flex-col w-full items-center justify-center min-h-[150px]">
              <p class="font-semibold mb-4 uppercase">KWCN Draw</p>
              <countdown utc-date-countdown="2024-12-05T17:00:00Z"></countdown>
            </div>
            <div v-else-if="showEmbedTwitch" class="mt-5">
              <iframe
                :src="setEmbedChannelSrc"
                :key="'embed' + embedLanguage"
                allowfullscreen
                height="470px"
                width="100%"
                class="max-w-full aspect-video w-full h-auto rounded-3xl"
              ></iframe>
              <div class="flex justify-center items-center gap-4 mt-3">
                <button
                  @click="embedLanguage = 'es'"
                  :class="{ 'underline font-bold': embedLanguage === 'es' }"
                >
                  ESP
                </button>
                <button
                  @click="embedLanguage = 'en'"
                  :class="{ 'underline font-bold': embedLanguage === 'en' }"
                >
                  ENG
                </button>
                <button
                  @click="embedLanguage = 'it'"
                  :class="{ 'underline font-bold': embedLanguage === 'it' }"
                >
                  ITA
                </button>
                <button
                  @click="embedLanguage = 'pt'"
                  :class="{ 'underline font-bold': embedLanguage === 'pt' }"
                >
                  POR
                </button>
              </div>
            </div>
          </div>

          <i18n-t keypath="nations.tournament.p1" tag="p" class="text-base bigsm:text-xl mb-5 mt-10">
            <template #kwcNationsStrong>
              <strong>{{ $t("nations.tournament.kwcNationsStrong") }}</strong>
            </template>
            <template #dateItaly
              ><strong>{{ $t("nations.tournament.dateItaly") }}</strong></template
            >
            <template #kwcNationsFormat
              ><strong>{{ $t("nations.tournament.kwcNationsFormat") }}</strong></template
            >
          </i18n-t>
          <i18n-t keypath="nations.tournament.p2" tag="p" class="text-base bigsm:text-xl mb-5">
            <template #kwcNationsFirstPhase>
              <strong>{{ $t("nations.tournament.kwcNationsFirstPhase") }}</strong>
            </template>
            <template #quarterFinals>
              <strong>{{ $t("nations.tournament.quarterFinals") }}</strong>
            </template>
            <template #lastChance>
              <strong>{{ $t("nations.tournament.lastChance") }}</strong>
            </template>
          </i18n-t>
          <i18n-t keypath="nations.tournament.p3" tag="p" class="text-base bigsm:text-xl mb-5">
            <template #finalDate>
              <strong>{{ $t("nations.tournament.finalDate") }}</strong>
            </template>
            <template #stadium>
              <strong>{{ $t("nations.tournament.stadium") }}</strong>
            </template>
            <template #firstChampSentence>
              <strong>{{ $t("nations.tournament.firstChampSentence") }}</strong>
            </template>
          </i18n-t>
        </div>
        <div class="bigsm:w-3/5">
          <!--          <NuxtImg
            :src="
              setAliasUrl('https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-shield.png')
            "
            alt="Kings World Cup"
            class="hidden bigsm:block w-[75px] absolute right-24 top-0"
            width="108"
            height="140"
          />-->

          <div class="px-6 pb-10 bigsm:px-0 bigsm:pb-0 bigsm:pr-6">
            <div :class="showCountdown || showEmbedTwitch ? 'bigsm:min-h-[350px]' : 'bigsm:min-h-[150px]'">
              <div
                v-if="showCountdown"
                class="hidden bigsm:flex bigsm:flex-col w-full items-center justify-center min-h-[350px]"
              >
                <p class="font-semibold mb-4 uppercase">KWCN Draw</p>
                <countdown utc-date-countdown="2024-12-05T17:00:00Z"></countdown>
              </div>
              <div v-else-if="showEmbedTwitch" class="hidden bigsm:block">
                <iframe
                  :src="setEmbedChannelSrc"
                  :key="'embed' + embedLanguage"
                  allowfullscreen
                  height="470px"
                  width="100%"
                  class="max-w-full aspect-video w-full h-auto rounded-3xl"
                ></iframe>
                <div class="flex justify-center items-center gap-4 mt-3">
                  <button
                    @click="embedLanguage = 'es'"
                    :class="{ 'underline font-bold': embedLanguage === 'es' }"
                  >
                    ESP
                  </button>
                  <button
                    @click="embedLanguage = 'en'"
                    :class="{ 'underline font-bold': embedLanguage === 'en' }"
                  >
                    ENG
                  </button>
                  <button
                    @click="embedLanguage = 'it'"
                    :class="{ 'underline font-bold': embedLanguage === 'it' }"
                  >
                    ITA
                  </button>
                  <button
                    @click="embedLanguage = 'pt'"
                    :class="{ 'underline font-bold': embedLanguage === 'pt' }"
                  >
                    POR
                  </button>
                </div>
              </div>
              <div v-else>
                <span></span>
              </div>
            </div>

            <NuxtImg
              :src="
                setAliasUrl(
                  'https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-trophy.png'
                )
              "
              alt="Trophy KWC Nations"
              class="w-1/2 max-w-[155px] mt-8 mx-auto"
              width="193"
              height="380"
              sizes="120px md:170px lg:240px"
            />
          </div>
        </div>
      </div>

      <div class="my-10 px-2">
        <NuxtImg
          :src="
            setAliasUrl(
              'https://cms-es.kingsleague.pro/wp-content/uploads/2024/12/kwc-nations-bracket-draw.png'
            )
          "
          alt="Bracket tournament"
          width="1320"
          height="auto"
          sizes="904px lg:1320px"
        />
      </div>
    </div>
  </section>
</template>

<style scoped></style>
