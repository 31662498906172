<!--
// nuxt-ui/components/pages/region-home/BannerHorizontalLargeHome.vue
-->
<script setup lang="ts">
const { locale } = useI18n();

const srcDesktop = computed(() => {
  return locale.value === "en"
    ? "/img/landings/brazil/banner-brazil-desktop-en.png"
    : "/img/landings/brazil/banner-brazil-desktop-pt.png";
});

const srcMobile = computed(() => {
  return locale.value === "en"
    ? "/img/landings/brazil/banner-brazil-mobile-en.png"
    : "/img/landings/brazil/banner-brazil-mobile-pt.png";
});
</script>

<template>
  <a href="https://brazil.apply.kingsleague.pro/" target="_blank" class="kql-banner-horizontal-container">
    <div
      class="flex flex-row flex-shrink gap-2.5 lg:gap-10 justify-between items-center max-w-[1440px] bigsm:px-2 h-full md:min-h-[117px]"
    >
      <div>
        <img :src="srcDesktop" alt="Join the draft" class="hidden w-full md:block" />
        <img :src="srcMobile" alt="Join the draft" class="md:hidden max-h-40" />
      </div>
    </div>
  </a>
</template>

<style scoped>
.kql-banner-horizontal-container {
  @apply flex justify-center min-h-[100px] items-center max-w-full p-2 bigsm:py-2 bigsm:px-4;
  @apply bg-[url('/img/landings/brazil/bg-banner-brazil-mobile.jpg')] md:bg-[url('/img/landings/brazil/bg-banner-brazil-desktop.jpg')] bg-cover bg-right-top md:min-h-[117px];
}

/*.kql-banner-horizontal-container-sunday {
  @apply flex justify-center items-center max-w-full max-h-max;
  @apply bg-[#20202A] bg-cover bg-center;
  background-image: url("https://cms-es.kingsleague.pro/wp-content/uploads/2024/03/solo-hoy-1-repeater-e1709921908549.jpg");
  background-position: top left;
  background-repeat: repeat-x;
  background-size: auto 32%;
}*/

/*@media (max-width: 640px) {
  .kql-banner-horizontal-container-sunday {
    background-size: auto 26%;
  }
}*/
</style>
