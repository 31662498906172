<!--
// nuxt-ui/components/base/BaseLivePopup.vue
-->
<script setup lang="ts">
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useTwitch } from "~/nuxt-ui/Shared/composables/twitch";
import { isFutureDate, isPastDate } from "~/src/Shared/utils";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

type Props = {
  classWindowPosition: string;
};

defineProps<Props>();

const { isSpain, isAmericas, isItaly } = useRegions();
const { isKings, isQueens } = useWebContext();
const { getTwitchLiveInfo } = useTwitch();

const isClosed = ref<boolean>(false);
const twitchIsLive = ref<boolean | undefined>(false);
const isMobile = ref<boolean | undefined>();
// const isBetweenSundayAndMonday = ref<boolean | undefined>(false);
// const isBetweenSaturdayAndSunday = ref<boolean | undefined>(false);
const isPlayoffsWeekSpain = ref<boolean | undefined>(false);
const isPlayoffsWeekAmericas = ref<boolean | undefined>(false);

const updateTwitchStatus = async (): Promise<void> => {
  const twitchInfo = await getTwitchLiveInfo();
  if (twitchInfo) {
    twitchIsLive.value = twitchInfo.live;
  }
};

onMounted(async () => {
  isMobile.value = window.innerWidth < 1025;
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth < 1025;
  });

  await updateTwitchStatus();

  // isBetweenSundayAndMonday.value =
  //   isKings && isPastDate("2024-12-08T10:00:00Z") && isFutureDate("2024-12-09T08:00:00Z");
  //
  // isBetweenSaturdayAndSunday.value =
  //   isQueens && isPastDate("2024-12-07T10:00:00Z") && isFutureDate("2024-12-08T08:00:00Z");

  isPlayoffsWeekSpain.value = isPastDate("2024-12-12T10:00:00Z") && isFutureDate("2024-12-15T23:59:00Z");
  isPlayoffsWeekAmericas.value = isPastDate("2024-12-14T10:00:00Z") && isFutureDate("2024-12-16T10:00:00Z");
});

const showSpainEmbedTwitch = computed(() => {
  return twitchIsLive.value && !isMobile.value && isSpain && !!isPlayoffsWeekSpain.value;
});

const showAmericasEmbedTwitch = computed(() => {
  return twitchIsLive.value && !isMobile.value && isAmericas && !!isPlayoffsWeekAmericas.value;
});
</script>

<template>
  <div :class="classWindowPosition" class="z-20 fixed">
    <div
      v-if="!isClosed && (showSpainEmbedTwitch || showAmericasEmbedTwitch)"
      class="flex flex-col rounded-lg overflow-hidden relative max-w-max border border-gray-700 bg-gray-900"
    >
      <div class="right-3 top-3 absolute cursor-pointer" @click="isClosed = !isClosed">
        <svg
          class="w-5 h-5 text-gray-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="black"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </div>
      <iframe
        v-if="isAmericas && showAmericasEmbedTwitch"
        src="https://player.twitch.tv/?channel=kingsleagueamericas&parent=kingsleague.pro&parent=queensleague.pro&muted=true"
        allowfullscreen
        height="300"
        width="400"
      ></iframe>
      <iframe
        v-else-if="isSpain && showSpainEmbedTwitch"
        src="https://player.twitch.tv/?channel=kingsleague&parent=kingsleague.pro&parent=queensleague.pro&muted=true"
        allowfullscreen
        height="300"
        width="400"
      ></iframe>
    </div>
  </div>
</template>

<style scoped></style>
