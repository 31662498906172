<!--
// nuxt-ui/components/pages/region-home/BannerFullWidthCms.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import bannerUiService from "~/src/content/infrastructure/ui-services/bannerUiService";
import type { BannerHome } from "~/src/content/domain/models/Banner";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { webContext } = useWebContext();
const { isWorldCup } = useRegions();
const { getHomeTopBanner } = bannerUiService();
const { locale } = useI18n();

const homeBanner = ref<BannerHome | undefined>();

homeBanner.value = await getHomeTopBanner(locale.value);

onMounted(async () => {
  if (!homeBanner.value) {
    await nextTick();
    homeBanner.value = await getHomeTopBanner(locale.value);
  }
});
</script>

<template>
  <section
    v-if="
      !!homeBanner &&
      homeBanner.webContext.includes(isWorldCup ? 'world-cup' : webContext) &&
      !homeBanner.isDouble
    "
  >
    <component
      :is="homeBanner.link !== '' ? 'a' : 'div'"
      :href="homeBanner.link"
      :target="homeBanner.linkTarget !== '' ? homeBanner.linkTarget : '_self'"
      class="kql-banner-horizontal-container flex md:hidden mobile-banner"
      :style="`background-image: url('${homeBanner.imageBgMobile}')`"
    >
      <div class="full-banner-image">
        <img :src="homeBanner.imageMobile" :alt="homeBanner.description" />
      </div>
    </component>
    <component
      :is="homeBanner.link !== '' ? 'a' : 'div'"
      :href="homeBanner.link"
      :target="homeBanner.linkTarget !== '' ? homeBanner.linkTarget : '_self'"
      class="kql-banner-horizontal-container hidden md:flex desktop-banner"
      :style="`background-image: url('${homeBanner.imageBgDesktop}')`"
    >
      <div class="full-banner-image">
        <img :src="homeBanner.imageDesktop" :alt="homeBanner.description" />
      </div>
    </component>
  </section>

  <section
    v-if="
      !!homeBanner &&
      homeBanner.webContext.includes(isWorldCup ? 'world-cup' : webContext) &&
      homeBanner.isDouble
    "
  >
    <div class="kql-double-banner-container">
      <component
        :is="homeBanner.linkLeft !== '' ? 'a' : 'div'"
        :href="homeBanner.linkLeft"
        :target="homeBanner.linkLeftTarget !== '' ? homeBanner.linkLeftTarget : '_self'"
        class="left-banner bigsm:!justify-end"
        :style="`background-image: url('${homeBanner.imageBgLeft}')`"
      >
        <div class="double-banner-image">
          <img :src="homeBanner.imageLeft" :alt="homeBanner.descriptionLeft" />
        </div>
      </component>

      <component
        :is="homeBanner.linkRight !== '' ? 'a' : 'div'"
        :href="homeBanner.linkRight"
        :target="homeBanner.linkRightTarget !== '' ? homeBanner.linkRightTarget : '_self'"
        class="right-banner bigsm:!justify-start"
        :style="`background-image: url('${homeBanner.imageBgRight}')`"
      >
        <div class="double-banner-image">
          <img :src="homeBanner.imageRight" :alt="homeBanner.descriptionRight" />
        </div>
      </component>
    </div>
  </section>
</template>

<style scoped>
.kql-banner-horizontal-container {
  @apply justify-center items-center max-w-full bg-cover bg-center md:max-h-[117px];
}

.kql-double-banner-container {
  @apply max-w-full lg:max-h-[117px] overflow-hidden flex flex-col bigsm:flex-row items-center justify-center;
}

.left-banner,
.right-banner {
  @apply flex justify-center items-center w-full bigsm:w-1/2 bg-cover bg-center bigsm:max-h-[117px];
}

.double-banner-image {
  @apply flex items-center justify-center w-full px-2 bigsm:max-w-[720px] h-full bigsm:max-h-[117px];
}

.full-banner-image {
  @apply flex justify-center items-center max-w-[1420px] px-2 h-full md:max-h-[117px];
}
</style>
